import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Spinner, Table } from 'react-bootstrap';

class EtsyListings extends Component {

  constructor() {
    super();
    this.state = {
      etsyProducts: [],
      loading: true
    };
    this.deleteEtsy = this.deleteEtsy.bind(this);
    this.activateEtsy = this.activateEtsy.bind(this);
    this.deactivateEtsy = this.deactivateEtsy.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("resource_owner_key") === null) {
      axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/token?referrer=etsylistings`, headers: {'Content-Type': 'application/json' }})
        .then(response => {
          window.location.href = response.data['login_url']
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
    else{
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }
      axios({ method: "GET", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/listings`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){
            this.setState({
              etsyProducts: response.data,
              loading: false
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
  }

  deleteEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      axios({ method: "delete", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/deletelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){
            this.setState({
              deleteResponse: response.data.results,
              loading: false
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
  }

  activateEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      axios({ method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/activatelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){
            this.setState({
              activateResponse: response,
              loading: false
            })
          }

          let etsyProducts = [...this.state.etsyProducts];
          var productIndex = etsyProducts.findIndex(function(product) {
              return product.etsy_id === etsy_id;
          });
          let product = {
              ...etsyProducts[productIndex],
              state: 'active'
          }
          product.state = 'active';
          etsyProducts[productIndex] = product;
          this.setState({etsyProducts});
        })
        .catch(error => console.log('error', error));
  }

  deactivateEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      axios({ method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/deactivatelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){
            this.setState({
              activateResponse: response,
              loading: false
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
  }

  render() {
    const productItems = this.state.etsyProducts.map((product) =>
      <tr>
        <td>{product.listing_id}</td>
        <td>{product.title}</td>
        <td>{product.price}</td>
        <td>{product.quantity}</td>
        <td>{product.views}</td>
        <td style={{textTransform: 'capitalize'}}>{product.state}</td>
        <td>
          <Button variant="danger" className="ui" onClick={()=>this.deleteEtsy(product.listing_id)}>Delete</Button>
          <a href={`${product.url}`}>
            <Button variant="primary" className="ui">View</Button>
          </a>
          { ['draft','edit'].includes(product.state) &&
            <Button variant="success" className="ui"onClick={()=>this.activateEtsy(product.listing_id)}>Activate</Button>
          }
          { product.state == 'active' &&
            <Button variant="warning" className="ui"onClick={()=>this.deactivateEtsy(product.listing_id)}>Deactivate</Button>
          }
        </td>
      </tr>
    )

    let data;

    if (this.state.loading)
    {
      data =
      <div className='body-cover'>
        <div className='body-spinner-container'>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    }
    else
    {
      data =
      <div>
        <Container>
          <h1 className="pageHeader">Products For Sale</h1>
          <Row>
            <Col>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Views</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {productItems}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    }
    return(
      <div className='main-container'>
      {data}
      </div>
    );
  }
}

export default EtsyListings;
