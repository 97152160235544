import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Button, Spinner, Jumbotron } from 'react-bootstrap';
import { HexGrid, Layout, Hexagon, Text, Pattern, GridGenerator } from 'react-hexgrid';
import { AuthUserContext } from '../Session';

class Catalogue extends Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      loading: true,
      categorySelected: false,
      category: '',
      imagesLoaded: 0,
      hexagons: GridGenerator.parallelogram(-2, 3, -2, 1)
    };
    this.selectCategory = this.selectCategory.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
  }


  selectCategory(category) {
    this.setState({
      loading: true,
      category: category
    });
    axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/products?category=${category}`, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
      .then(response => {
        this.setState({
          products: response.data,
          loading: false,
          categorySelected: true
        });
      })
      .catch(error => console.log('error', error));
  }

  componentDidMount() {
    let query_string = new URLSearchParams(this.props.location.search);
    let params = new URLSearchParams(query_string);
    let style = params.get('style');

    style ?
      this.selectCategory(style)
    :
      axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/products`, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          this.setState({
            products: response.data,
            loading: false
          });
        })
        .catch(error => console.log('error', error));
  }

  deleteProduct(id) {
    axios({ method: "delete", url: `${process.env.REACT_APP_API_ENDPOINT}/delete/${id}`, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
      .then(response => {
        if (response.status == '200'){
          console.log(response.data)
          this.setState({
            deleteResponse: response.data.results,
            loading: false
          })
        }
        // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
      })
      .catch(error => console.log('error', error));
  }
  imageLoaded() {
    var uploaded = this.state.imagesLoaded;
    this.setState({
      imagesLoaded: uploaded + 2
    });
  }

  render() {

    const productItems = this.state.products.map((product) =>
      <Col key={product.id} style={{marginTop:'20px'}} xs={12} sm={12} md={12} lg={6} >
        <Card>
          <Card.Body>
            <Card.Title>{product.title}</Card.Title>
            <Card.Img variant="top" key={product.id} src={`${product.primary_image}?authuser=0`} onLoad={this.imageLoaded.bind(this)}/>
            <Card.Text className='mt-4 mb-4'>
              {product.description}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Link href={'item/' + product.id}>
              <Button className="positive ui button">View</Button>
            </Card.Link>
            <AuthUserContext.Consumer>
            {authUser =>
              authUser &&
                <span>
                  <Card.Link href={'item/' + product.id + '/edit'}>
                    <Button className="positive ui button">Edit</Button>
                  </Card.Link>
                  <Button className="negative ui button danger" onClick={()=>this.deleteProduct(product.id)}>Delete</Button>
                </span>
            }
            </AuthUserContext.Consumer>
            <p style={{float:'right',fontSize:'1.8em',marginTop:'5px'}}>
              <b>£ {product.price.toFixed(2)}</b>
            </p>
          </Card.Footer>
        </Card>
      </Col>
    );

    let data;

    if (this.state.loading) {

      data =
        <div className='body-cover'>
          <div className='body-spinner-container'>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </div>

    }
    else if (this.state.categorySelected) {

      data =
        <div>
          <Container>
            <Row>
              <Col className='mt-3'>
                <h1 className="pageHeader">{ this.state.category }</h1>
              </Col>
            </Row>
            <Row>
              {productItems}
            </Row>
          </Container>
        </div>

    }
    else {

      data =
        <div>
          <Jumbotron fluid>
            <HexGrid width='100%' height={1000}>
              <Layout size={{ x: 18, y: 18 }} flat={true} spacing={1.05} origin={{ x: 0, y: 0 }}>
                {/*<Hexagon q={-3} r={0}>
                  <Text>A1</Text>
                </Hexagon>*/}
                {/*<Hexagon q={-2} r={0} fill='needle-felting-pattern'>
                  <Text>B1</Text>
                </Hexagon>*/}
                {/*<Hexagon q={-1} r={-1}>
                  <Text>C1</Text>
                </Hexagon>*/}
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Needle Felting')} q={2} r={-2} s={0} fill='needle-felting-pattern'>
                  <Text>Needle Felting</Text>
                </Hexagon>
                {/*<Hexagon q={1} r={-2}>
                  <Text>E1</Text>
                </Hexagon>*/}
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Christmas')} q={0} r={-1} s={0} fill='christmas-pattern'>
                  <Text>Christmas Decoration</Text>
                </Hexagon>
                {/*<Hexagon q={3} r={-3}>
                  <Text>G1</Text>
                </Hexagon>*/}
                {/*<Hexagon q={-3} r={1}>
                  <Text>A2</Text>
                </Hexagon>*/}
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Easter')} q={-2} r={1} s={0} fill='easter-pattern'>
                  <Text>Easter Decorations</Text>
                </Hexagon>
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Wood Burning')} q={-1} r={0} s={0} fill='woodburning-pattern'>
                  <Text>Wood Burning</Text>
                </Hexagon>
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Halloween')} q={0} r={0} s={0} fill='halloween-pattern'>
                  <Text>Halloween</Text>
                </Hexagon>
                <Hexagon class='catalogue-polygon' onClick={()=>this.selectCategory('Toys & Baby')} q={1} r={-1} s={0} fill='toys-pattern'>
                  <Text>Toys & Baby</Text>
                </Hexagon>
                {/*<Hexagon q={2} r={-1} fill='christmas-pattern'>
                  <Text>F2</Text>
                </Hexagon>*/}
                {/*<Hexagon q={3} r={-2}>
                  <Text>G2</Text>
                </Hexagon>*/}
                {/*<Hexagon q={-3} r={2}>
                  <Text>A3</Text>
                </Hexagon>*/}
                {/*<Hexagon q={-2} r={2}>
                  <Text>B3</Text>
                </Hexagon>*/}
                <Hexagon class='catalogue-polygon' q={-1} r={1} s={0} fill='wedding-pattern'>
                  <Text>Wedding Ideas</Text>
                </Hexagon>
                {/*<Hexagon q={0} r={1}>
                  <Text>D3</Text>
                </Hexagon>*/}
                <Hexagon class='catalogue-polygon' q={1} r={0} s={0} fill='present-pattern'>
                  <Text>Present</Text>
                </Hexagon>
                {/*<Hexagon q={2} r={0}>
                  <Text>F3</Text>
                </Hexagon>*/}
                {/*<Hexagon q={3} r={-1}>
                  <Text>G3</Text>
                </Hexagon>*/}
              </Layout>
              <Pattern id="needle-felting-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="christmas-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="easter-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="woodburning-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/woodburning.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="halloween-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="toys-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="wedding-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
              <Pattern id="present-pattern" link="https://storage.googleapis.com/scufi-firebase.appspot.com/signal-2020-11-26-133806.jpg" size={{ x: 18, y: 18 }} />
            </HexGrid>
          </Jumbotron>
        </div>

    }

    return (
      <div className={this.state.categorySelected ? 'main-container' : 'main-container' }>
      {data}
      </div>
    );
  }
}

export default Catalogue;
