import React from 'react';
import { Nav } from 'react-bootstrap';
import { withFirebase } from '../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faSignOutAlt );

const SignOutButton = ({ firebase }) => (
  <Nav.Link to='' onClick={firebase.doSignOut}>
    <FontAwesomeIcon className='nav-bar-right' icon='sign-out-alt' size='1x' />
  </Nav.Link>
);

export default withFirebase(SignOutButton);
