import React from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import '@fortawesome/fontawesome';
import { AuthUserContext } from '../Session';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignInAlt, faUserAlt, faCoffee} from '@fortawesome/free-solid-svg-icons';

library.add(faSignInAlt, faUserAlt, faCoffee );

const Navigation = () => (
  <div style={{marginBottom: '0px', zIndex: '100', position:'relative'}}>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser ? <NavigationAuth /> : <NavigationNonAuth />
      }
    </AuthUserContext.Consumer>
  </div>
);

const NavigationAuth = () => (
  <div>
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand href={ROUTES.HOME}>
          <h1 className='logo-header'>Scufi</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="site-header sticky-top py-1" style={{background: 'none'}}>
            <div className="container d-flex flex-column flex-md-row justify-content-between">
              <Nav.Link href={ROUTES.HOME}>Home</Nav.Link>
              <Nav.Link href={ROUTES.STORY}>My Story</Nav.Link>
              <Nav.Link href={ROUTES.CATALOGUE}>Catalogue</Nav.Link>
              <NavDropdown title="Admin" id="collasible-nav-dropdown">
                <NavDropdown.Item href={ROUTES.PRODUCTCREATE}>New Product</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href={ROUTES.ADMIN}>Admin</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href={ROUTES.ETSYSALES}>Etsy Sales</NavDropdown.Item>
                <NavDropdown.Item href={ROUTES.ETSYLISTINGS}>Etsy Listings</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href={ROUTES.CONTACT}>Contact</Nav.Link>
            </div>
          </Nav>
          <Nav>
            <Nav.Link href={ROUTES.ACCOUNT}>
              <FontAwesomeIcon icon='user-alt' size='1x' />
            </Nav.Link>
            <SignOutButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </div>




);

const NavigationNonAuth = () => (


  <div>
    <Container>
      <Navbar expand="lg">
        <Navbar.Brand href="/">
          <h1 className='logo-header'>Scufi</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="site-header sticky-top py-1" style={{background: 'none'}}>
            <div className="container d-flex flex-column flex-md-row justify-content-between">
              <Nav.Link href={ROUTES.HOME}>Home</Nav.Link>
              <Nav.Link href={ROUTES.STORY}>My Story</Nav.Link>
              <Nav.Link href={ROUTES.CATALOGUE}>Catalogue</Nav.Link>
              <Nav.Link href={ROUTES.CONTACT}>Contact</Nav.Link>
            </div>
          </Nav>
          <Nav>
            <Nav.Link href={ROUTES.SIGN_IN}>
              <FontAwesomeIcon className='contact-icon' icon='sign-in-alt' size='1x' />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  </div>
);


export default Navigation;
