import React, { Component } from 'react';
import { GridGenerator } from 'react-hexgrid';
import { Jumbotron, Container, Row, Col } from 'react-bootstrap';
import configs from './configurations'

class HomePage extends Component {
  constructor(props) {
    super(props);
    const config = configs['hexagon'];
    const generator = GridGenerator.getGenerator(config.map);
    const hexagons = generator.apply(this, config.mapProps);
    this.state = { hexagons, config };
  }

  changeType(event) {
    const name = event.currentTarget.value;
    const config = configs[name];
    const generator = GridGenerator.getGenerator(config.map);
    const hexagons = generator.apply(this, config.mapProps);
    this.setState({ hexagons, config });
  }

  render() {
    return (
      <div>
        <Jumbotron style={{marginTop: '-100px', marginBottom: '100px'}} fluid>
          <img alt='Scufi Craft' style={{width: '100%',opacity:'0.3'}} src='https://images.unsplash.com/photo-1533382433768-3d403240fa40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1400&q=80' />
        </Jumbotron>

        <Container>
          <Row>
            <Col>
              <h1 style={{textAlign:'center'}}>Scufi - making beautiful objects from the every day</h1>
              <p>
                Here at Scufi we are trying to approach art and crafts in a different way.
                Instead of buying material we seek to re-use everyday items. We have found uses
                for everything from recyclable plastic to wool caught on fences in the countryside.
                <br/>
                The same goes for our packaging so please understand if you get your needle-felted
                easter eggs in an egg carton.
              <br/>

              </p>
            </Col>
          </Row>
        </Container>
        <br/>
        <br/>

        <div className="container fluid">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="card hoverable mb-4" data-toggle="modal" data-target="#basicExampleModal">
                <img className="card-img-top" src="https://mdbootstrap.com/img/Photos/Others/img3.jpg" alt="Needle felting" />
                <div className="card-body">
                  <h2 className="my-3">Needle Felting</h2>
                  <p className="card-text mb-3">We have a variety of needle felted objects, all made with natural wool and reused materials.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card hoverable mb-4" data-toggle="modal" data-target="#basicExampleModal">
                <img className="card-img-top" src=" https://mdbootstrap.com/img/Photos/Others/img4.jpg" alt="Christmas Decorations" />
                <div className="card-body">
                  <h2 className="my-3">Christmas Decorations</h2>
                  <p className="card-text mb-3">From our hand made rose-covered christmas baubles to Christmas hampers we have a variety of options.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="card hoverable mb-4" data-toggle="modal" data-target="#basicExampleModal">
                <img className="card-img-top" src=" https://mdbootstrap.com/img/Photos/Others/img10.jpg" alt="Gifts & Ideas" />
                <div className="card-body">
                  <h2 className="my-3">Gifts & Ideas</h2>
                  <p className="card-text text-uppercase mb-3">From children toys to wood burned portraits we can help you celebrate that special occasion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
