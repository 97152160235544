import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faMapMarketAlt, faPhone, faEnvelopeOpen, faSignInAlt, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {BrowserRouter as NavLink, Link} from 'react-router-dom'

library.add(faUser, faSignInAlt, faSignOutAlt, faFacebookF, faTwitter, faInstagram, faLinkedin, faEnvelopeOpen );


var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
}

var phantom = {
  display: 'block',
  padding: '20px',
  height: '60px',
  width: '100%',
}

const Footer = () => (

  <div>
    <div style={phantom} />
    <div style={style}>


        <Row>

          <Col className="col-md-3 fh5co-widget">
            <h4 style={{color:'#f6e7d8'}}>About Retail Tribe Marketing</h4>
            <p style={{color:'#f6e7d8'}}>We understand and can demonstrate what good retail looks like.</p>
          </Col>

          <Col className="col-md-3 col-md-push-1">
            <h4 style={{color:'#f6e7d8'}}>Latest Posts</h4>
            <ul className="fh5co-footer-links">
              <li><a style={{color:'#f6e7d8'}} href="/blog-1">The Demise of Arcadia</a></li>
              <li><a style={{color:'#f6e7d8'}} href="/blog">Understanding how social media can set you apart</a></li>
              <li><a style={{color:'#f6e7d8'}} href="/blog">New trends show old habits die hard</a></li>
            </ul>
          </Col>

          <Col className="col-md-3 col-md-push-1">
            <h4 style={{color:'#f6e7d8', textAlign:'center'}}>Links</h4>
            <ul className="fh5co-footer-links">
              <li><Link to="Home"><p style={{color:'#f6e7d8', textAlign:'center'}}>Home</p></Link></li>
              <li><Link to="Work"><p style={{color:'#f6e7d8', textAlign:'center'}}>Work</p></Link></li>
              <li><Link to="Blog"><p style={{color:'#f6e7d8', textAlign:'center'}}>Blog</p></Link></li>
              <li><Link to="Contact"><p style={{color:'#f6e7d8', textAlign:'center'}}>Contact</p></Link></li>
              <li><Link to="About"><p style={{color:'#f6e7d8', textAlign:'center'}}>About us</p></Link></li>
            </ul>
          </Col>

          <Col className="col-md-3">
            <h4 style={{color:'#f6e7d8'}}>Contact Information (Ir)</h4>
            <ul className="fh5co-footer-links">
              <li>1 Main Street, Dublin <br/> Ireland</li>
              <li><a style={{color:'#f6e7d8'}} href="tel://+353851047018">+353-8510-47018</a></li>
              <li><a style={{color:'#f6e7d8'}} href="mailto:alison@retailtribemarketing.com">sales@retailtribemarketing.com</a></li>
              <li><a style={{color:'#f6e7d8'}} href="http://retailtribemarketing.com">retailtribemarketing.com</a></li>
            </ul>
          </Col>

        </Row>
    </div>
  </div>
);



export default Footer;
