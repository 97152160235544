import React, { Component } from 'react';
import {Col, Row, Card, Button, Spinner} from 'react-bootstrap';
import axios from 'axios';
import { Container, Header } from 'semantic-ui-react'
import { AuthUserContext } from '../Session';
import ImageGallery from 'react-image-gallery';

class ProductView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      product: {},
      loading: true,
      onEtsy: true
    };
    this.displayImages = this.displayImages.bind(this);
    this.postToEtsy = this.postToEtsy.bind(this);
  }

  componentDidMount() {
    axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/products/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json' }})
      .then(response => {
        this.setState({
          product: response.data,
          loading: false,
          onEtsy: response.data.etsy_published
        })
      })
      .catch(error => console.log('error', error));
  }

  displayImages() {
    const image_gallery_array = [];
    const options = {
      showNav: false,
      showFullscreenButton: false,
      lazyLoad: true,
      autoPlay: true
    }

    image_gallery_array.push({ original: `${this.state.product.primary_image + '?authuser=0'}`, thumbnail: `${this.state.product.primary_image + '?authuser=0'}` });
    this.state.product.images.map((product_image, index) => {
      image_gallery_array.push({ original: `${product_image + '?authuser=0'}`, thumbnail: `${product_image + '?authuser=0'}` });
    })
    return (
      <ImageGallery
        {...options}
        items={image_gallery_array} />
    );
  }

  deleteEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      this.setState({
        loading: true
      })

      axios({ method: "delete", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/deletelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){

            let product = {
                ...this.state.product,
                state: ''
            }

            this.setState({
              product: product,
              loading: false,
              onEtsy: false,
              etsy_url: ''
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
  }

  activateEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      axios({ method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/activatelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){

            let product = {
                ...this.state.product,
                state: 'active'
            }

            this.setState({
              product: product,
              loading: false,
              onEtsy: true
            })
          }
        })
        .catch(error => console.log('error', error));
  }

  deactivateEtsy(etsy_id) {
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }

      axios({ method: "get", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/deactivatelisting/${etsy_id}`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){

            let product = {
                ...this.state.product,
                state: 'draft'
            }

            this.setState({
              product: product,
              loading: false,
              onEtsy: true
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
  }

  postToEtsy(event) {
    event.preventDefault();
    if (localStorage.getItem("resource_owner_key") === null) {
      axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/token?referrer=test`, headers: {'Content-Type': 'application/json' }})
        .then(response => {
          window.location.href = response.data['login_url']
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
    else{
      this.setState({
        loading: true
      })

      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret')
      }

      axios({ method: "POST", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/${this.props.match.params.id}`, data: JSON.stringify(data), headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){

            let product = {
                ...this.state.product,
                etsy_id: response.data['listing_id'],
                etsy_url: response.data['url'],
                state: 'draft'
            }

            this.setState({
              product: product,
              loading: false,
              onEtsy: true,
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
  }

  render() {
    let data;
    if (this.state.loading)
    {
      data =
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    }
    else
    {
      data =
      <div>
        <Row>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} sm={6} md={7} lg={8}>
                  <div className="card-image">
                    {this.displayImages()}
                  </div>
                </Col>
                <Col xs={12} sm={6} md={5} lg={4} >
                  <Header style={{textAlign:'center'}} as='h1'>{this.state.product.title}</Header>
                  <div className='mb-3'>
                    <p style={{fontSize: '1.4em' }}>Price: <b style={{float:'right'}}>£{this.state.product.price} </b></p>
                    <p>{this.state.product.description}</p><br/>
                  </div>
                  <div className='mb-3'>
                    <p><b>Colours: &nbsp;</b>
                      {this.state.product.colors.map((color) =>
                        <span style={{float:'right',backgroundColor:color,color:'#fff',fontWeight:'bold',padding:'2px 5px',margin:'0 5px'}}>{color} </span>
                      )}
                    </p>
                  </div>
                  <div className='mb-3'>
                    <p style={{width:'100%'}}><b>Materials:</b> <span style={{float:'right'}}>{this.state.product.materials.join(", ")}</span></p>
                  </div>
                  <div className='mb-3'>
                    <p><b>Tags:</b> <span style={{float:'right'}}>{this.state.product.tags.join(", ")} </span> </p>
                  </div>
                  <div className='mb-3'>
                    <p><b>Weight:</b> <span style={{float:'right'}}>{this.state.product.weight}kg </span></p>
                  </div>
                  <div className='mb-3'>
                    <p style={{width:'100%'}}><b>Dimensions (HxWxD):</b> <span style={{float:'right'}}>{this.state.product.height_dimension}cm x {this.state.product.width_dimension}cm x {this.state.product.depth_dimension}cm </span></p>
                  </div><br/><br/>
                  <div className='mb-3' style={{display:'block',clear:'both'}}>
                    {this.state.product.style.map((styley) =>
                      <a href={'/catalogue?style=' + styley}>
                        <Button variant="outline-primary" style={{marginRight: '10px'}}>{styley}</Button>
                      </a>
                    )}
                  </div>
                  { this.state.product.state === 'active' &&
                    <a href={'https://www.etsy.com/uk/listing/' + this.state.product.etsy_id}>
                      <Button size='lg' style={{position:'absolute',right:'0',bottom:'0'}} variant="success">Buy on Etsy</Button>
                    </a>
                  }

                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <AuthUserContext.Consumer>
              {authUser =>
                authUser &&
                  <span>
                    <Card.Link href={'/item/' + this.state.product.id + '/edit'}>
                      <Button style={{marginRight:'5px'}} size='lg' variant="warning">Edit</Button>
                    </Card.Link>
                    <Button style={{marginRight:'25px'}} size='lg' variant="danger" onClick={()=>this.deleteProduct(this.state.product.id)}>Delete</Button>

                    { ['draft','edit'].includes(this.state.product.state) &&
                      <Button style={{marginRight:'5px'}} size='lg' variant="info" onClick={()=>this.activateEtsy(this.state.product.etsy_id)}>Activate Etsy Listing</Button>
                    }

                    { this.state.product.state === 'active' &&
                      <Button style={{marginRight:'5px'}} size='lg' variant="warning" onClick={()=>this.deactivateEtsy(this.state.product.etsy_id)}>Deactivate Etsy Listing</Button>
                    }

                    { this.state.onEtsy ?
                      <Button style={{marginRight:'5px'}} size='lg' variant="danger" onClick={()=>this.deleteEtsy(this.state.product.etsy_id)}>Delete from Etsy</Button>
                      :
                      <Button style={{marginRight:'5px'}}  size='lg' variant="success" onClick={this.postToEtsy}>Post to Etsy</Button>
                    }

                  </span>
              }
              </AuthUserContext.Consumer>
              <p style={{float:'right',fontSize:'1.8em',marginTop:'5px'}}>
                <b>£ {this.state.product.price.toFixed(2)}</b>
              </p>
            </Card.Footer>
          </Card>
        </Row>
      </div>
      }
      return (
        <div className='main-container'>
          <Container>
            {data}
          </Container>
        </div>

      );
    }
  }

export default ProductView;
