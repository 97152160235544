import React, { Component } from 'react';
import { Table, Row, Container } from 'react-bootstrap';
import { withFirebase } from '../Firebase';

class AdminPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.firebase.users().on('value', snapshot => {
      const usersObject = snapshot.val();
      const usersList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      this.setState({
        users: usersList,
        loading: false,
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;
    return (
      <div>
        <Container>
          <Row>
            <h1>Admin</h1>
            {loading && <div>Loading ...</div>}
            <UserList users={users} />
          </Row>
        </Container>
      </div>
    );
  }

}

const UserList = ({ users }) => (
  <Table striped bordered hover>
    <thead>
      <tr>
        <th>#</th>
        <th>Username</th>
        <th>Email</th>
      </tr>
    </thead>
    <tbody>
      {users.map(user => (
        <tr key={user.uid}>
          <td>{user.uid}</td>
          <td>{user.username}</td>
          <td>{user.email}</td>
        </tr>
      ))}
    </tbody>
  </Table>
);

export default withFirebase(AdminPage);
