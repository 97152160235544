import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Navigation from '../Navigation';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import Catalogue from '../Catalogue';
import ProductView from '../ProductView';
import ProductCreate from '../ProductCreate';
import ProductEdit from '../ProductEdit';
import Contact from '../Contact';
import Story from '../Story';
import OAuth from '../OAuth';
import Footer from '../Footer';
import EtsyListings from '../EtsyListings';
import EtsySales from '../EtsySales';
import * as ROUTES from '../../constants/routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import { withAuthentication } from '../Session';

const App = () => (
  <Router>
    <main role="main">
      <Navigation />
      <Route exact path={ROUTES.HOME} component={HomePage} />
      <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route path={ROUTES.SIGN_IN} component={SignInPage} />
      <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route path={ROUTES.ACCOUNT} component={AccountPage} />
      <Route path={ROUTES.ADMIN} component={AdminPage} />
      <Route path={ROUTES.CATALOGUE} component={Catalogue} />
      <Route exact path={ROUTES.PRODUCTVIEW} component={ProductView} />
      <Route exact path={ROUTES.PRODUCTEDIT} component={ProductEdit} />
      <Route exact path={ROUTES.PRODUCTCREATE} component={ProductCreate} />
      <Route path={ROUTES.CONTACT} component={Contact} />
      <Route path={ROUTES.STORY} component={Story} />
      <Route path={ROUTES.OAUTH} component={OAuth} />
      <Route path={ROUTES.ETSYSALES} component={EtsySales} />
      <Route path={ROUTES.ETSYLISTINGS} component={EtsyListings} />
      <Footer />
    </main>
  </Router>
);

export default withAuthentication(App);
