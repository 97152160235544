import React, { Component } from 'react';
import axios from 'axios';
import {Col, Row, Container, Spinner } from 'react-bootstrap';
import ProductForm from '../ProductForm';

class ProductEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      product: {},
      loading: true
    };
  }

  componentDidMount() {
    axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/products/${this.props.match.params.id}`, headers: {'Content-Type': 'application/json' }})
      .then(response => {
        console.log(response)
        this.setState({ product: response.data, loading: false })
      })
      .catch(error => console.log('error', error));
  }

  render() {
  let data;
  if (this.state.loading)
  {
    data =
    <div className='body-cover'>
      <div className='body-spinner-container'>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    </div>
  }
  else
  {
    data =
        <ProductForm data={this.state.product} />
      }
      return (
        <div>
          <Container>
            <Row>
              <Col lg="12">
                <h1>Edit Product</h1>
              </Col>
            </Row>
            <Row>
              {data}
            </Row>
          </Container>
        </div>
      );
  }
}

export default ProductEdit;
