import React, { Component } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEtsy, faFacebook } from '@fortawesome/free-brands-svg-icons';

library.add(fab, faPhone, faFacebook, faEtsy );

class Contact extends Component {
  constructor() {
    super();
    this.props = {
    };
  }
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12} sm={9} md={9} lg={8} >
              <p>We would love to hear your ideas for all things beautiful and crafty so feel free to get in touch via any of the means below. </p><br/>
              <p></p>
            </Col>
            <Col xs={12} sm={9} md={3} lg={4} >
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={7} md={8} lg={8} >
              <p>Get in touch</p>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="text" placeholder="Name" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" rows={3}  placeholder="Message"  />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
                </Button>
              </Form>
            </Col>
            <Col xs={12} sm={5} md={4} lg={4} >
              <br/>
              <br/>
              <p><FontAwesomeIcon icon={['fab', 'etsy']} size='1x' />  etsy.com</p>
              <br/>
              <p><FontAwesomeIcon icon={['fab', 'facebook']} size='1x' />  facebook.com</p>
              <br/>
              <p><FontAwesomeIcon icon='phone' size='1x' /> 07534268373</p>

            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Contact;
