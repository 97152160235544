import React, { Component } from 'react';
import {v4 as uuid} from "uuid";
import {Col, Row, Form, InputGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import MultipleValueTextInput from 'react-multivalue-text-input';
import FileBase64 from 'react-file-base64';
import { Dropdown } from 'semantic-ui-react'
import '@firebase/auth'

const materials = [
  { key: "Wood", text: "Wood", value: "Wood" },
  { key: "Wool", text: "Wool", value: "Wool" },
  { key: "Glass", text: "Glass", value: "Glass" },
  { key: "Clay", text: "Clay", value: "Clay" },
  { key: "Plastic", text: "Plastic", value: "Plastic" },
  { key: "Cardboard", text: "Cardboard", value: "Cardboard" },
  { key: "Paper", text: "Paper", value: "Paper" },
  { key: "Aluminium", text: "Aluminium", value: "Aluminium" }
]

class ProductForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      product: {
        id: uuid(),
        title: "",
        description: "",
        price:  "",
        materials: [], // array
        style: [],// Style of this listing. Each style is a free-form text string such as "Formal", or "Steampunk". A Listing may have up to two styles.
        colors: [],
        weight: 0,
        height_dimension: 0,
        width_dimension: 0,
        depth_dimension: 0,
        postage_options: "",
        primary_image: {},
        images: [],
        is_customizable: true,// boolean
        non_taxable: "",
        etsy_state: "",
        product_state: "",// One of active, removed, sold_out, expired, alchemy, edit, create, private, or unavailable.
        processing_min: "",
        processing_max: "",
        category_id: "",
        taxonomy_id: "",
        tags: [],
        who_made: "",// i_did, collective, someone_else
        is_supply: "",
        when_made: "",// made_to_order, 2010_2019, 2000_2009, before_2000, 1990s, 1980s, 1970s, 1960s, 1950s, 1940s, 1930s, 1920s, 1910s, 1900s, 1800s, 1700s, before_1700
        recipient: "",
        occasions: [], // anniversary, baptism, bar_or_bat_mitzvah, birthday, canada_day, chinese_new_year, cinco_de_mayo, confirmation, christmas, day_of_the_dead, easter, eid, engagement, fathers_day, get_well, graduation, halloween, hanukkah, housewarming, kwanzaa, prom, july_4th, mothers_day, new_baby, new_years, quinceanera, retirement, st_patricks_day, sweet_16, sympathy, thanksgiving, valentines, wedding
        quantity: "",
        shipping_template_id: "",
        etsy_published: false
      },
      error: '',
      imageSources: []
      //
    };
    this.getMultiSelectValue = this.getMultiSelectValue.bind(this);
    this.getPrimaryImage = this.getPrimaryImage.bind(this);
    this.getImages = this.getImages.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeForm = this.onChangeForm.bind(this);
  }

  componentWillMount() {
    let imageArray = [];
    if (this.props.data && this.props.data.images) {
      this.props.data.images.map((image, index) => {
        imageArray.push(<img key={index} style={{"width": "20%"}} alt={`${image}`} src={`${image}?authuser=0`} />);
      })

      this.setState({ product: this.props.data,  imageSources: imageArray, loading: false })
    }
    else{
      this.setState({ imageSources: imageArray, loading: false })
    }
  }

  onChangeForm(event) {
    event.preventDefault();
    let product = {
        ...this.state.product,
         [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value
    }

    this.setState({
      product: product
    });
  }

  getMultiSelectValue(event, {value}) {
    let product = {
      ...this.state.product,
      materials: value
    }

    this.setState({
      product: product
    });
  }

  getPrimaryImage(primary_image){

    let product = {
        ...this.state.product,
        primary_image: primary_image
    }

    this.setState({
      product: product
    });
  }

  getImages(images){
    let imageArray = [];
    let product = {
        ...this.state.product,
        images: images
    }

    images.map((image, index) => {
      imageArray.push(<img key={index} style={{"width": "20%",marginRight:'10px'}} alt={`${image}`} src={`${image.base64}`} />);
    })

    this.setState({
      product: product,
      imageSources: imageArray,
      loading: false
    });

  }

  onSubmit(event) {
    console.log(this.state.product);
    event.preventDefault();
    axios({ method: "POST", url: `${process.env.REACT_APP_API_ENDPOINT}/add`, data: JSON.stringify(this.state.product), headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
    .then((response) => {
      this.props.history.push(`/item/1`);
    })
    .catch(error => this.setState({ errors: "none" }));
  }

  render() {
    const submitText = this.state.product.title ? 'Update' : 'Create' ;
    return (
        <Col lg="12">
          <Form onSubmit={this.onSubmit}>
            <Form.Group >
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={this.onChangeForm}
                defaultValue={this.state.product.title} />
            </Form.Group>
            <Form.Group controlId="formBasicDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                rows="5"
                onChange={this.onChangeForm}
                defaultValue={this.state.product.description} />
            </Form.Group>
            <Form.Group controlId="formBasicPrice">
              <Form.Label>Price</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>£</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  aria-label="Amount (to the nearest pound)"
                  type="number"
                  name="price"
                  onChange={this.onChangeForm}
                  defaultValue={this.state.product.price} />
                <InputGroup.Append>
                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
            <Form.Group controlId="formBasicQuantity">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="number"
                name="quantity"
                min="1"
                step="1"
                defaultValue={this.state.product.quantity}
                onChange={this.onChangeForm} />
            </Form.Group>
            <Form.Group controlId="formBasicStyle">
              <Form.Label>Colours</Form.Label>
              <MultipleValueTextInput
                values={this.state.product.colors}
                className="ui fluid form-control"
                onItemAdded={(colorItem, allColorItems) => this.setState({ colors: allColorItems })}
                onItemDeleted={(colorItem, allColorItems) => this.setState({ colors: allColorItems })}
                name="colors"
                placeholder="Enter whatever colours you want; separated with COMMA or ENTER."
              />
            </Form.Group>
            <Form.Group controlId="formBasicMaterials">
              <Form.Label>Materials</Form.Label>
              <Dropdown name="materials" id="materials" ref="materials" selection
              value={this.state.product.materials}
              fluid multiple clearable selection options={materials} onChange={this.getMultiSelectValue}  />
            </Form.Group>
            <Form.Group controlId="formBasicMaterials">
              <Form.Label>Weight (g)</Form.Label>
              <Form.Control
                type="number"
                name="weight"
                min="1"
                step="1"
                defaultValue={this.state.product.weight}
                onChange={this.onChangeForm} />
            </Form.Group>
            <Form.Group controlId="formBasicMaterials">
              <Form.Label>Dimensions (cm) H x W x D</Form.Label>
              <Row>
                <Col lg='4'>
                  <Form.Control
                    type="number"
                    name="height_dimension"
                    min="1"
                    step="1"
                    placeholder='Height'
                    defaultValue={this.state.product.height_dimension}
                    onChange={this.onChangeForm} />
                </Col>
                <Col lg='4'>
                  <Form.Control
                    type="number"
                    name="width_dimension"
                    min="1"
                    step="1"
                    placeholder='Width'
                    defaultValue={this.state.product.width_dimension}
                    onChange={this.onChangeForm} />
                </Col>
                <Col lg='4'>
                  <Form.Control
                    type="number"
                    name="depth_dimension"
                    min="1"
                    step="1"
                    placeholder='Depth'
                    defaultValue={this.state.product.depth_dimension}
                    onChange={this.onChangeForm} />
                </Col>
              </Row>
            </Form.Group>
            <Form.Group controlId="formBasicCustomisable">
              <Form.Label>Postage Options</Form.Label>
              <Form.Control ref='postage_options' as="select" value={this.state.product.postage_options} name="postage_options" onChange={(evt) => this.onChangeForm(evt)}>
                <option value="Express">Express</option>
                <option value="Next Day">Next Day</option>
                <option value="1st Class">1st Class</option>
                <option value="2nd Class">2nd Class</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicStyle">
              <Form.Label>Style</Form.Label>
              <MultipleValueTextInput
                className="ui fluid form-control"
                onItemAdded={(styleItem, allStyleItems) => this.setState({ style: allStyleItems })}
                onItemDeleted={(styleItem, allStyleItems) => this.setState({ style: allStyleItems })}
                values={this.state.product.style}
                name="style"
                placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
              />
            </Form.Group>
            <Form.Group controlId="formBasicCustomisable">
              <Form.Label>Customisable</Form.Label>
              <Form.Control ref='is_customizable' as="select" value={this.state.product.is_customizable} name="is_customizable" onChange={(evt) => this.onChangeForm(evt)}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicState">
              <Form.Label>State</Form.Label>
              <Form.Control as="select" name="product_state" value={this.state.product.product_state} ref="product_state" onChange={(evt) => this.onChangeForm(evt)} >
                <option value="active">Active</option>
                <option value="draft">Draft</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicTags">
              <Form.Label>Tags</Form.Label>
              <MultipleValueTextInput
                className="form-control"
                onItemAdded={(tagItem, allTagItems) => this.setState({ tags: allTagItems })}
                onItemDeleted={(tagItem, allTagItems) => this.setState({ tags: allTagItems })}
                name="tag"
                values={this.state.product.tags}
                placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
              />
            </Form.Group>
            <Form.Group controlId="formBasicMadeBy">
              <Form.Label>Who Made</Form.Label>
              <Form.Control ref='who_made' as="select" name="who_made" onChange={(evt) => this.onChangeForm(evt)}>
                <option value='I did'>I did</option>
                <option value='Collective'>Collective</option>
                <option value='Someone else'>Someone else</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicWhenMade">
              <Form.Label>Production Date</Form.Label>
              <Form.Control ref='when_made' as="select" name="when_made" onChange={(evt) => this.onChangeForm(evt)}>
                <option value="made_to_order">Made To Order</option>
                <option value="2020_2020">2020</option>
                <option value="2010_2019">2010-2019</option>
                <option value="2001_2009">2001-2009</option>
                <option value="before_2001">Pre 2001</option>
                <option value="2000_2000">2000-2000</option>
                <option value="1990s">1990's</option>
                <option value="1980s">1980's</option>
                <option value="1970s">1970's</option>
                <option value="1960s">1960's</option>
                <option value="1950s">1950's</option>
                <option value="1940s">1940's</option>
                <option value="1930s">1930's</option>
                <option value="1920s">1920's</option>
                <option value="1910s">1910's</option>
                <option value="1900s">1900's</option>
                <option value="1800s">1800's</option>
                <option value="1700s">1700's</option>
                <option value="before_1700">Pre 1700</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicState">
              <Form.Label>Occasion</Form.Label>
              <Form.Control as="select" name="occasion" ref="occasion" onChange={(evt) => this.onChangeForm(evt)} >
                <option value="anniversary">Anniversary</option>
                <option value="baptism">Baptism</option>
                <option value="bar_or_bat_mitzvah">Bar Or Bat Mitzvah</option>
                <option value="birthday">Birthday</option>
                <option value="canada_day">Canada Day</option>
                <option value="chinese_new_year">Chinese New Year</option>
                <option value="cinco_de_mayo">Cinco de Mayo</option>
                <option value="confirmation">Confirmation</option>
                <option value="christmas">Christmas</option>
                <option value="day_of_the_dead">Day Of The Dead</option>
                <option value="easter">Easter</option>
                <option value="eid">Eid</option>
                <option value="engagement">Engagement</option>
                <option value="fathers_day">Father's Day</option>
                <option value="get_well">Get Well</option>
                <option value="graduation">Graduation</option>
                <option value="halloween">Halloween</option>
                <option value="hanukkah">Hanukkah</option>
                <option value="housewarming">Housewarming</option>
                <option value="kwanzaa">Kwanzaa</option>
                <option value="prom">Prom</option>
                <option value="july_4th">July 4th</option>
                <option value="mothers_day">Mothers Day</option>
                <option value="new_baby">New Baby</option>
                <option value="new_years">New Years</option>
                <option value="quinceanera">Quinceanera</option>
                <option value="retirement">Retirement</option>
                <option value="st_patricks_day">St Patricks Day</option>
                <option value="sweet_16">Sweet 16</option>
                <option value="sympathy">Sympathy</option>
                <option value="thanksgiving">Thanksgiving</option>
                <option value="valentines">Valentines</option>
                <option value="wedding">Wedding</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Primary Image</Form.Label>
              <div className="ui right fluid">
                <FileBase64
                  ref="primary_image"
                  accept=".jpg, .jpeg"
                  className="form-control"
                  onDone={ this.getPrimaryImage.bind(this) } />
              </div>
            </Form.Group>
            <div>
              { this.state.product.primary_image ?
                <img style={{"width": "20%"}} alt={`${this.state.product.primary_image}`} src={`${this.state.product.primary_image}`} />
              :
                <div></div>
              }
            </div>
            <Form.Group>
              <Form.Label>Secondary Images</Form.Label>
              <div className="ui right fluid">
                <FileBase64
                  ref="images"
                  multiple={ true }
                  accept=".jpg, .jpeg"
                  className="form-control"
                  onDone={ this.getImages.bind(this) } />
              </div>
            </Form.Group>
            <div>
              {this.state.imageSources.map((image, index) => {
                return (image)
              })}
            </div>
            <Button style={{float:'right'}} size='lg' className="btn btn-success" type="submit">
              {submitText}
            </Button>
          </Form>
        </Col>
    );
  }
}

export default ProductForm;
