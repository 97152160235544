
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const CATALOGUE = '/catalogue';
export const PRODUCTEDIT = '/item/:id/edit';
export const PRODUCTVIEW = '/item/:id';
export const PRODUCTCREATE = '/items/create';
export const CONTACT = '/contact';
export const STORY = '/story';
export const OAUTH = '/oauth';
export const ETSYSALES = '/etsysales';
export const ETSYLISTINGS = '/etsylistings';
