import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';

class Story extends Component {
  constructor() {
    super();
    this.props = {
    };
  }
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col>
              <br/>
              <h1>Making beautiful things </h1>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Story;
