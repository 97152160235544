import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: "AIzaSyCy8SacSpYUyr97xpGSpqRxN2rg-SeTQWA",
  authDomain: "scufi-firebase.firebaseapp.com",
  databaseURL: "https://scufi-firebase.firebaseio.com",
  projectId: "scufi-firebase",
  storageBucket: "scufi-firebase.appspot.com",
  messagingSenderId: "351616483705",
  appId: "1:351616483705:web:5a77565a855b34f32cafa8",
  measurementId: "G-JPT8JF17SH"
};



class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.database();
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  user = uid => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');
}

export default Firebase;
