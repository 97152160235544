import React, { Component } from 'react';
import { Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
const PasswordForgetPage = () => (
  <Col>
    <h1>PasswordForget</h1>
    <PasswordForgetForm />
  </Col>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { error } = this.state;
    return (
      <Col>
        <br/>
        <Form onSubmit={this.onSubmit}>
          <Form.Group controlId="formBasicTitle">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="email"
              value={this.state.email}
              onChange={this.onChange}
              type="text"
              placeholder="Email Address" />
          </Form.Group>
          <Button variant="primary" type="submit">
            Reset My Password
          </Button>
          {error && <p>{error.message}</p>}
        </Form>
        <br/>
        <br/>
      </Col>
    );
  }
}
const PasswordForgetLink = () => (
  <Col>
    <p>
      <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
    </p>
  </Col>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };
