import React from 'react'
import { Row, Col, Container} from 'react-bootstrap';
import { AuthUserContext, withAuthorization } from '../Session';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
      <div>
        <Container>
          <Row>
            <Col>
              <h1>Account: {authUser.email}</h1>
            </Col>
          </Row>
          <Row>
            <PasswordForgetForm />
          </Row>
          <Row>
            <PasswordChangeForm />
          </Row>
        </Container>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = authUser => !!authUser;

export default withAuthorization(condition)(AccountPage);
