import React, { Component } from 'react';
import axios from 'axios';
import { Container, Row, Col, Spinner, Table } from 'react-bootstrap';

class EtsySales extends Component {

  constructor() {
    super();
    this.state = { etsySales: [], loading: true, imagesLoaded: 0};
  }

  componentDidMount() {
    if (localStorage.getItem("resource_owner_key") === null) {
      axios({method: 'GET', url: `${process.env.REACT_APP_API_ENDPOINT}/token?referrer=etsysales`, headers: {'Content-Type': 'application/json' }})
        .then(response => {
          window.location.href = response.data['login_url']
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
    else{
      let data = {
        'resource_owner_key': localStorage.getItem('resource_owner_key'),
        'resource_owner_secret': localStorage.getItem('resource_owner_secret'),
      }
      axios({ method: "GET", url: `${process.env.REACT_APP_API_ENDPOINT}/etsy/sales`, params: data, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }})
        .then(response => {
          if (response.status == '200'){
            this.setState({
              etsySales: response.data.results,
              loading: false
            })
          }
          // window.open(`${response.data['login_url']}`, 'sharer', 'toolbar=0,status=0,width=808,height=725')
        })
        .catch(error => console.log('error', error));
    }
  }

  render() {
    const sales = this.state.etsySales.map((sale) =>
          <tr>
              <td>{`${sale.transaction_id}`}</td>
              <td>{`${sale.title}`}</td>
              <td>{`${sale.description}`}</td>
              <td>{`${sale.quantity}`}</td>
              <td>{`${sale.price}`}</td>
              <td>
                <a href={`${sale.url}`}>View</a>
              </td>
          </tr>
    )

    let data;
    if (this.state.loading)
    {
      data =
      <div className='body-cover'>
        <div className='body-spinner-container'>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    }
    else
    {
      data =
      <div>
        <Container>
          <h1 className="pageHeader">Etsy Sales</h1>
          <Row>
            <Col>
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Listing ID</th>
                  </tr>
                </thead>
                <tbody>
                  {sales}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    }
    return (
      <div className='main-container'>
      {data}
      </div>

    );
  }
}

export default EtsySales;
