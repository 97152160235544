import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { AuthUserContext, withAuthorization } from '../Session';
import ProductForm from '../ProductForm';

class ProductCreate extends Component {
  constructor() {
    super();
    this.state = {
      loading: true
    }
  }

  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <Container>
              <Row>
                <Col lg="12">
                  <h1>Create Product</h1>
                </Col>
              </Row>
              <Row>
                <ProductForm data={this.state}/>
              </Row>
            </Container>
          </div>
        )}
      </AuthUserContext.Consumer>
    )
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(ProductCreate);
