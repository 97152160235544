import React, { Component } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import queryString from 'query-string';

class OAuth extends Component {
  constructor() {
    super();
    this.props = {
    };
  }

  componentDidMount() {
    let oauth_values = queryString.parse(this.props.location.search)
    axios({method: 'POST', url: `${process.env.REACT_APP_API_ENDPOINT}/authenticate`, headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }, data: oauth_values})
      .then(response => {
        localStorage.setItem('resource_owner_key', response.data['oauth_token'])
        localStorage.setItem('resource_owner_secret', response.data['oauth_token_secret'])
        window.close();
        this.props.history.push(oauth_values['referrer'])
      })
      .catch(error => console.log('error', error));
  }


  render() {
    return (
      <div className='body-cover'>
        <div className='body-spinner-container'>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    )
  }

}

export default OAuth;
